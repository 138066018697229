<template>
    <div class="header">
        <h1 class="title">Discord Whois</h1>
        <div class="navigation">
            <router-link to="/" class="item">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                <p>New whois</p>
            </router-link>
            <router-link to="/lookup?id=163707345459740673"  class="item">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path></svg>
                <p>Demo</p>
            </router-link>
            <router-link to="/about"  class="item">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                <p>About</p>
            </router-link>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.header {
    padding: 10px 0;
    display: flex;
    align-items: center;

    .title {
        font-size: 27px;
    }
    .navigation {
        display: flex;
        margin-left: 10px;

        .item {
            display: flex;
            align-items: center;
            padding: 10px;
            border-radius: 10px;
            transition-duration: 0.5s;
            cursor: pointer;
            text-decoration: none;
            color: white;

            svg {
                width: 20px
            }
            p {
                margin-left: 5px;
                font-weight: 500;
            }
        }
        .item:hover {
            background: #4753C2;
        }
    }
}
@media only screen and (max-width: 700px) {
    .header {
        display: block;
        width: 120%;
        margin-left: -10%;
        
        .title {
            text-align: center;
        }
        .navigation {
            margin: 0 auto;
            width: 80%;
            justify-content: space-between;
        }
    }
}
</style>
