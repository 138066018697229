<template>
    <div class="home">
        <div class="promo">
            <h2>NEW!</h2>
            <p>
                You can download a users avatar and/or banner in high
                resolution!
            </p>
        </div>

        <div class="intro">
            <h1 class="title">Discord Whois</h1>
            <h2 class="subtitle">Created by Gerbenkr.eu</h2>
        </div>

        <div class="content">
            <p v-if="state.error" class="error">{{ state.error }}</p>
            <form action="#" @submit.prevent="formSubmit">
                <input
                    v-model="discordId"
                    class="discord-id-input"
                    type="text"
                    placeholder="Enter the Discord user, server or message ID here"
                />
                <input
                    class="discord-id-submit umami--click--whois"
                    type="submit"
                    value="Whois"
                />
            </form>
            <router-link class="link" to="how-do-i-obtain-a-id"
                >How do i obtain a ID?</router-link
            >
        </div>
    </div>
</template>
<script>
import { onMounted, ref, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
    setup() {
        const router = useRouter();
        const route = useRoute();

        const state = reactive({
            error: "",
        });
        const discordId = ref("");

        function formSubmit() {
            const onlyNumbers = /^\d+$/.test(discordId.value); // true = only numbers || false = not only numbers
            if (
                !discordId.value ||
                discordId.value.length != 18 ||
                !onlyNumbers
            ) {
                return (state.error = "Please enter a valid Discord ID!");
            }
            router.push({
                path: "/lookup",
                query: {
                    id: discordId.value.toString(),
                },
            });
        }

        function checkError() {
            if (route.query.error) {
                if (route.query.error == "invalid_id") {
                    state.error = "Please enter a valid Discord ID!";
                } else {
                    state.error = "Something whent wrong!";
                }
            }
        }

        onMounted(() => {
            checkError();
        });

        return {
            state,
            discordId,
            formSubmit,
            checkError,
        };
    },
};
</script>
<style lang="scss" scoped>
.intro {
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .title {
        font-size: 45px;
    }
    .subtitle {
        font-size: 18px;
        font-weight: 500;
        margin-top: -5px;
    }
}
.promo {
    display: flex;
    align-items: center;
    background: #4753c2;
    border-radius: 10px;
    padding: 10px;

    h2 {
        font-size: 20px;
    }
    p {
        margin-left: 5px;
    }
}
.content {
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .error {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 30px;
        color: white;
        background: #ed4245;
        font-weight: 500;
        font-size: 15px;
    }
    .discord-id-input {
        margin-top: 25px;
        width: 100%;
        padding: 20px;
        border: none;
        border-radius: 30px;
        color: black;
        font-weight: 600;
        font-size: 15px;
    }
    ::placeholder {
        color: #606060;
        font-weight: 500;
        font-size: 15px;
    }

    .discord-id-submit {
        margin-top: 25px;
        // width: 23%;
        width: 200px;
        padding: 20px;
        border: none;
        border-radius: 30px;
        color: white;
        background: #4753c2;
        font-weight: 600;
        font-size: 15px;
        transition-duration: 0.3s;
        cursor: pointer;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    .discord-id-submit:hover {
        background: #353f96;
    }

    .link {
        color: white;
        margin-top: 8px;
    }
}

.footer {
    position: absolute;
    bottom: 10px;

    p {
        font-size: 16px;
    }
}
</style>
