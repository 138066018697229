<template>
    <div class="lookup">
        <div class="content" v-if="!state.loading">
            <Introduction v-if="state.data.type == 'account'" :data="state.data" />
            <IDInfo v-if="state.data.type == 'account'" :data="state.data" />
            <Creation :data="state.data" />
            <DownloadImages v-if="state.data.avatar || state.data.banner_image" :data="state.data" />
        </div>
        <div v-if="state.loading" class="loading">
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useReCaptcha } from 'vue-recaptcha-v3';

import Introduction from '@/components/Lookup/Introduction.vue';
import IDInfo from '@/components/Lookup/IDInfo.vue';
import Creation from '@/components/Lookup/Creation.vue';
import DownloadImages from '@/components/Lookup/DownloadImages.vue';

export default {
    components: {
        Introduction,
        IDInfo,
        Creation,
        DownloadImages,
    },
    setup() {

        const route = useRoute();
        const router = useRouter();
        const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

        const state = reactive({
            id: '',
            loading: true,
            data: {
                info: {}
            }
        })

        async function loadData(id){
            
            await recaptchaLoaded();
            const token = await executeRecaptcha('login');

            const response = await fetch(`https://api.gerbenkr.nl/v2/whois.gerbenkr.nl/lookup?id=${id}&recaptcha=${token}`);
            console.log(response);

            if (response.ok) {
                const json = await response.json();
                state.data = json.data;
                state.loading = false;
            } else {
                router.push({
                    path: '/',
                })
                console.log(`Something when wrong!`);
                console.log(response);
                return;
            }
        }

        onMounted(() => {
            const onlyNumbers = /^\d+$/.test(route.query.id); // true = only numbers || false = not only numbers
            if (!route.query.id || route.query.id.length != 18 || !onlyNumbers) {
                router.push({
                    path: '/',
                    query: {
                        error: 'invalid_id',
                    }
                })
                return;
            }
            loadData(route.query.id);
        })

        return {
            state,
            loadData,
        }
    }
}
</script>
<style lang="scss" scoped>
.content {
    margin-top: 5px;
}

.loading {
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

</style>
