<template>
    <div class="textpage">
        <h1>How to obtain a ID</h1>
        <h2>Desktop</h2>
        <ul>
            <li>Open User settings</li>
            <li>Open 'Behaviour'</li>
            <li>Activate 'Developer Mode'</li>
        </ul>
        <ul>
            <li>Right click on a guild, account, channel or message</li>
            <li>Click 'Copy ID'</li>
        </ul>
        <br>
        <h2>Andriod</h2>
        <ul>
            <li>Open User settings</li>
            <li>Open 'Appearance'</li>
            <li>Activate 'Developer Mode'</li>
        </ul>
        <ul>
            <li>Open a guild, account, channel or message</li>
            <li>Click the tree dots (for a message hold for a second)</li>
            <li>Click 'Copy ID'</li>
        </ul>
        <br>
        <h2>iOS</h2>
        <ul>
            <li>Open User settings</li>
            <li>Open 'Appearance'</li>
            <li>Open 'Advanced'</li>
            <li>Activate 'Developer Mode'</li>
        </ul>
        <ul>
            <li>Open a guild, account, channel or message</li>
            <li>Click the tree dots (for a message hold for a second)</li>
            <li>Click 'Copy ID' </li>
        </ul>
    </div>
</template>