import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Lookup from '../views/Loopup.vue';
import About from '../views/About.vue';
import ObtainId from '../views/ObtainId.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/lookup',
        name: 'Lookup',
        component: Lookup
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/how-do-i-obtain-a-id',
        name: 'how-do-i-obtain-a-id',
        component: ObtainId
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Home',
        component: Home
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
