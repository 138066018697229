<template>
    <div class="profile">
        <img v-if="this.data.avatar" :src="this.data.avatar" alt="" class="profile-image">
        <div class="info">
            <h1 class="username">{{ this.data.username }}#{{ this.data.discriminator }}</h1>
            <h2 class="id">{{ this.data.id }}</h2>

            <div class="buttons">
                <Button v-if="this.data.account_url" :link="this.data.account_url" text="Go to Discord profile" icon='<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path></svg>' />
                <!-- <Button text="Share" icon='<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"></path></svg>' /> -->
            </div>
        </div>
    </div>
</template>
<script>
import Button from '@/components/Button.vue';

export default {
    props: [ 'data' ],
    components: {
        Button,
    }    
}
</script>
<style lang="scss" scoped>
.profile {
    display: flex;
    align-items: center;

    .profile-image {
        width: 160px;
        border-radius: 50%;
        margin-right: 10px;
    }
    .info {
        .username {
            font-size: 35px;
        }
        .id {
            font-weight: 400;
            font-size: 17px;
            margin-top: -7px;
            color: rgb(238, 238, 238);
        }
        .buttons {
            margin-top: 12px;
            display: flex;
        }
    }
}
@media only screen and (max-width: 600px) {
    .profile {
        flex-direction: column;
        text-align: center;

        .profile-image {
            width: 150px;
            margin-right: 0;
        }
        
    }
}
</style>