<template>
    <div class="wrapper">
        <div class="container">
            <Header />
            <router-view/>
        </div>
    </div>
</template>
<script>
import Header from '@/components/Header.vue';

export default {
    components: {
        Header,
    }    
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}
body {
    color: white;
    background: #5865F2;
}
.wrapper {
    max-width: 2000px;
    margin: 0 auto;
}
.container {
    width: 60%;
    margin: 0 auto;
}

.textpage {
    border-top: 1px solid white;

    h1 {
        margin-top: 10px;
        font-size: 27px;
    }
    h2 {
        font-size: 20px;
        font-weight: 600;
    }
    ul {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        margin-left: 20px !important;
    }
    a {
        color: white !important;
    }
}

.inline {
    display: flex;
    align-items: center;

    svg {
        width: 25px;
        margin-right: 3px;
    }
}
.inline-svg-small {
    svg {
        width: 20px !important;
    }
}

@media only screen and (max-width: 1000px) {
    .container {
        width: 80%;
    }
}
@media only screen and (max-width: 600px) {
    .container {
        width: 90%;
    }
}

</style>
