<template>
    <div class="date-info">
        <div class="inline">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            <h2 class="title">Creation date:</h2>
        </div>
        <div class="box">
            <div class="item">
                <p class="name">Timestamp:</p>
                <p class="value">{{ this.data.timestamp }}</p>
            </div>
            <div class="item">
                <p class="name">Time ago:</p>
                <p class="value">{{ this.data.ago }}</p>
            </div>
            <div class="item">
                <p class="name">Created (12H):</p>
                <p class="value">{{ this.data.created12 }}</p>
            </div>
            <div class="item">
                <p class="name">Created (24H):</p>
                <p class="value">{{ this.data.created24 }}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: [ 'data' ],
}
</script>
<style lang="scss" scoped>
.date-info {
    margin-top: 20px;
    .title {
        font-size: 19px;
        font-weight: 600;
    }
    .box {
        margin-top: 5px;
        background: #4753C2;
        padding: 15px;
        border-radius: 10px;

        .item {
            display: flex;
            margin-top: 8px;
            font-size: 17px;

            .name {
                font-weight: 600;

                svg {
                    margin-right: 5px;
                }
            }
            .value {
                margin-left: 5px;
                font-weight: 400;
            }
        }
        :first-child {
            margin-top: 0;
        }
    }
}
</style>