<template>
    <div class="account-info">
        <div class="inline">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            <h2 class="title">Account information:</h2>
        </div>
        <div class="box">
            <div class="item">
                <p class="name">Username:</p>
                <p class="value">{{ this.data.username }}</p>
            </div>
            <div class="item">
                <p class="name">Discriminator:</p>
                <p class="value">#{{ this.data.discriminator }}</p>
            </div>
            <div class="item">
                <p class="name">ID:</p>
                <p class="value">{{ this.data.id }}</p>
            </div>
            <div class="item">
                <p class="name">Bot:</p>
                <p v-if="this.data.isbot == true" class="value">yes</p>
                <p v-if="this.data.isbot == false" class="value">no</p>
            </div>
            <div class="item">
                <p class="name">Profile color:</p>
                <p v-if="this.data.profile_color" class="value">{{ this.data.profile_color }}</p>
                <p v-else class="value">not set</p>
                <div class="color-circle" :style="'background-color:' + this.data.profile_color"></div>
            </div>
            <div class="item">
                <p class="name">Avatar:</p>
                <p v-if="this.data.avatar != null" class="value">yes</p>
                <p v-if="this.data.avatar == null" class="value">no</p>
            </div>
            <div class="item">
                <p class="name">Banner:</p>
                <p v-if="this.data.banner_image != null" class="value">yes</p>
                <p v-if="this.data.banner_image == null" class="value">no</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: [ 'data' ],
}
</script>
<style lang="scss" scoped>
.account-info {
    margin-top: 20px;
    .title {
        font-size: 19px;
        font-weight: 600;
    }
    .box {
        margin-top: 5px;
        background: #4753C2;
        padding: 15px;
        border-radius: 10px;

        .item {
            display: flex;
            align-items: center;
            margin-top: 8px;
            font-size: 17px;

            .name {
                font-weight: 600;

                svg {
                    margin-right: 5px;
                }
            }
            .value {
                margin-left: 5px;
                font-weight: 400;
            }

            .color-circle {
                width: 17px;
                height: 17px;
                border-radius: 100%;
                margin-left: 5px;
            }
        }
        :first-child {
            margin-top: 0;
        }
    }
}
</style>