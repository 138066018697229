<template>
    <div>
        <button v-if="this.theme == 'light'" @click="buttonClick" class="custom-button button-light">
            <div v-if="this.icon" v-html="this.icon"></div>
            {{ this.text }}
        </button>
        <button v-else @click="buttonClick" class="custom-button">
            <div v-if="this.icon" v-html="this.icon"></div>
            {{ this.text }}
        </button>
    </div>
</template>
<script>
export default {
    props: ['text', 'icon', 'link', 'theme'],
    setup(props){

        function buttonClick(){
            if (props.link) {
                window.open(props.link, '_blank').focus();
            }
        }

        return {
            buttonClick,
        }
    }
}
</script>
<style lang="scss" scoped>
.custom-button {
    border: none;
    border-radius: 7px;
    background: #4753C2;
    color: white;
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 500;
    // margin-left: 10px;
    transition: 0.3s;
    cursor: pointer;
    display: flex;
    align-items: center;

    div {
        margin-left: -5px;
        width: 20px;
        display: flex;
        align-items: center;
        margin-right: 5px;
    }
}
.custom-button:first-child {
    margin-left: 0;
}
.custom-button:hover {
    background: #353f96;
}

.button-light {
    background: #5865f2 !important;
}
</style>