<template>
    <div class="textpage">
        <h1>About</h1>
        <p>Discord Whois is a simple tool that allows you to get information about a Discord user, guild, channel or message without inviting a bot to your Discord server. This tool is created by gerbenkr.eu as a side-project and is free to use. If you have any questions please read this page or send me a Discord message.</p>
        <br>
        <h1>Privacy</h1>
        <ul>
            <li><a href="https://analytics.gerbenkr.nl/">https://analytics.gerbenkr.nl/</a> - a hosted version of Umami, an open source tool to have an overview of views, visitors and events on the website. Analytics are anonymous.</li>
            <li><a href="https://discord.com">https://discord.com</a> - This website does not store any Discord data. See the <a href="https://discord.com/terms">Discord TOS</a> for more information about their data usage.</li>
        </ul>
        <br>
        <h1>Credits</h1>
        <ul>
            <li><a href="https://heroicons.dev" target="_blank">Heroicons</a> - for all icons that are used in this app.</li>
            <li><a href="https://fonts.google.com" target="_blank">Google Fonts</a> - for all fonts that are used in this app.</li>
            <li><a href="https://discord.com" target="_blank">Discord API</a> - for all Discord data.</li>
            <li><a href="https://loading.io/css/" target="_blank">loading.io</a> - for the loading animation.</li>
        </ul>
        <br>
        <h1>Disclaimer</h1>
        <ul>
            <li>This website is not affiliated with Discord Inc.</li>
            <li>It is not allowed to use our API without any permission.</li>
        </ul>
        <br>
        <h1>Contact</h1>
        <p>If you have feedback or a question you can send me a Discord DM by pressing the button below. </p>
        <Button style="margin-top: 10px" link="https://discord.com/users/163707345459740673" text="Contact me" icon='<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path></svg>' />
    </div>
</template>
<script>
import Button from '@/components/Button.vue';

export default {
    components: {
        Button,
    }    
}
</script>