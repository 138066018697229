<template>
    <div class="download">
        <div class="inline">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path></svg>
            <h2 class="title">Downloads:</h2>
        </div>
        <div class="images">
            <div v-if="this.data.avatar" class="download-image" id="avatar">
                <h2 class="title">Avatar</h2>
                <img id="avatar_image" :src="this.data.avatar + '?size=600'">
                <Button @click="downloadImage(this.data.avatar + '?size=600', 'avatar')" theme="light" text="Download avatar" icon='<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path></svg>'/>
            </div>

            <div v-if="this.data.banner_image" class="download-image" id="avatar">
                <h2 class="title">Banner</h2>
                <img id="banner_image" :src="this.data.banner_image + '?size=600'">
                <Button @click="downloadImage(this.data.banner_image + '?size=600', 'banner')" theme="light" text="Download banner" icon='<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path></svg>'/>
            </div>
        </div>
    </div>
</template>
<script>
import Button from '@/components/Button.vue';

export default {
    props: [ 'data' ],
    components: {
        Button,
    },
    setup(props){

        async function downloadImage(imageSrc, type) {
            const image = await fetch(imageSrc)
            const imageBlog = await image.blob()
            const imageURL = URL.createObjectURL(imageBlog)

            const link = document.createElement('a')
            link.href = imageURL;
            link.download = `${props.data.username}'s ${type}`;
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }

        return {
            downloadImage
        }
    }    
}
</script>
<style lang="scss" scoped>
.download {
    margin-top: 20px;
    .title {
        font-size: 19px;
        font-weight: 600;
    }

    .images {
        display: flex;
        justify-content: space-between;

        .download-image {
            margin-left: 15px;
            margin-top: 5px;
            background: #4753C2;
            padding: 15px;
            border-radius: 10px;

            #avatar_image {
                margin-top: 10px;
                max-width: 240px;
                width: 100%;
                min-width: 150px;
                border-radius: 10px;
                margin-bottom: 5px;
            }

            #banner_image {
                margin-top: 10px;
                width: 100%;
                min-width: 300px;
                border-radius: 10px;
                margin-bottom: 5px;
            }
        }:first-child {
            margin-left: 0;
        }
    }
}

@media only screen and (max-width: 800px) {
    .images {
        flex-wrap: wrap;

        .download-image {
            margin-left: 0 !important;
            margin-top: 10px !important;
        }
        
        #avatar_image {
            max-width: 100% !important;
            min-width: 0 !important;
        }
        #banner_image {
            max-width: 100% !important;
            min-width: 0px !important;
        }
    }
}

</style>